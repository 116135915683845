import {LitElement, html, css, customElement, property} from 'lit-element';
import {style} from '../styles';
import '../components/md-view';
import '../components/site-footer';
import {defaultClient} from '../services/http-services';

@customElement("term-page")
class TermPage extends LitElement {

    static get styles() {
        return [style, css`
            :host {
              background-color: antiquewhite;
            }
            div {
              padding: 1em;
              margin-left: auto;
              margin-right: auto;
              max-width: 65em;
            }
        `];
    }

    @property({attribute: true})
    termUrl: string;

    termMdData?: string;

    constructor() {
        super();
        this.termUrl = '';
    }

    render() {
        return html`
            <div>
                <md-view mdData="${this.termMdData}"></md-view>
            </div>
            <site-footer></site-footer>
        `;
    }
    connectedCallback() {
        super.connectedCallback();
        let idx = this.termUrl.lastIndexOf('/');
        let baseUrl = this.termUrl.substring(0, idx);
        let file = this.termUrl.substring(idx);
        defaultClient.get(file, {baseUrl, type: 'text'}).then((async(data:any) => {
            this.termMdData = await data.text();
            this.performUpdate();
        }).bind(this));
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'term-page': TermPage;
    }
}
