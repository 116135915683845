import {LitElement, html, css, customElement, property} from 'lit-element';
import {style} from '../styles';
import '../components/md-view';
import '../components/site-footer';
import {defaultClient} from '../services/http-services';
import docsHome from 'url:../../resources/docs-home.md';
import cliDocs from 'url:../../resources/cli-docs.md';
import buildScriptDocs from 'url:../../resources/build-script-docs.md';
import quickStart from 'url:../../resources/quick-start.md';

@customElement("docs-page")
class DocsPage extends LitElement {

    static get styles() {
        return [style, css`
            :host {
              background-color: white;
            }
            div {
              padding: 1em;
              margin-left: auto;
              margin-right: auto;
              max-width: 65em;
            }
        `];
    }

    @property({attribute: true})
    docsName: string;

    docsMdData?: string;

    constructor() {
        super();
        this.docsName = 'docs-home';
    }

    render() {
        return html`
            <div>
                <md-view mdData="${this.docsMdData}"></md-view>
            </div>
            <site-footer></site-footer>
        `;
    }
    connectedCallback() {
        super.connectedCallback();
        let docUrl = docsHome;
        switch (this.docsName) {
            case 'cli': docUrl = cliDocs; break;
            case 'build-script-api': docUrl = buildScriptDocs; break;
            case 'quick-start': docUrl = quickStart; break;
        }
        let idx = docUrl.lastIndexOf('/');
        let baseUrl = docUrl.substring(0, idx);
        let file = docUrl.substring(idx);
        defaultClient.get(file, {baseUrl, type: 'text'}).then((async(data:any) => {
            this.docsMdData = await data.text();
            this.performUpdate();
        }).bind(this));
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'docs-page': DocsPage;
    }
}
