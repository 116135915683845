import {html, customElement, LitElement, property, css} from "lit-element";

@customElement("toggle-button")
class ToggleButton extends LitElement {

    static get styles() {
        return css`
            :host {

            }
            .switch {
                position: relative;
                display: inline-block;
                width: 40px;
                height: 24px;
            }

            .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: var(--secondary-light);
                transition: background-color 0.4s;
                border-radius: 16px;
            }

            .slider_on {
                background-color: var(--primary-color);
            }

            .disabled {
                background-color: var(--secondary-dark);
            }

            .slider::before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                transition: left 0.5s;
                border-radius: 50%;
            }
            .slider_on::before {
                left: 20px;
            }
        `;
    }

    private _checked: boolean = false;
    private _disabled: boolean = false;

    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    get checked(): boolean {
        return this._checked;
    }

    @property({attribute: true})
    set checked(value: any) {
        const isChecked = ['undefined', 'null', 'false'].indexOf(''+value) == -1;
        if (isChecked !== this._checked) {
            this._checked = isChecked;
            this.dispatchEvent(new CustomEvent('change', { detail: { checked: this._checked } }));
        }
    }

    @property({attribute: true})
    set disabled(value: any) {
        this._disabled = ['undefined', 'null', 'false'].indexOf(''+value) == -1;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    private handleClick() {
        if (['undefined', 'null', 'false'].indexOf(''+this.disabled) != -1) {
            this.checked = !this.checked;
            (<HTMLElement>this.shadowRoot?.querySelector('.slider')).classList.toggle('slider_on');
        }
    }

    render() {
        return html`
          <label class="switch">
            <input type="checkbox" .checked="${this.checked}" .disabled="${this.disabled}">
            <span class="slider ${this.checked ? 'slider_on' : ''} ${this.disabled ? 'disabled' : ''}" @click="${this.handleClick}"></span>
          </label>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'toggle-button': ToggleButton;
    }
}
