import {LitElement, html, customElement, css, unsafeCSS} from 'lit-element';
import '../components/site-footer';
import s1Back from '../../resources/00045.jpg';
import darkBack from '../../resources/dark_backg.webp';
import lightBack from '../../resources/light_backg2.jpg';
import complex01 from '../../resources/complex_01.png';
import complex02 from '../../resources/complex_02.png';
import quickBuild from 'url:../../resources/build_js_quick.mp4';

@customElement("home-not-logged")
class HomeNotLogged extends LitElement {

	static get styles() {
		return css`
			:host {
				display: block;
				align-items: center;
				background: transparent;
			}

			section {
				display: block;
				padding: 4em;
				padding-top: 6em;
				padding-bottom: 6em;
				text-align: center;
			}

			section h1, section h2, section h3, section p {
				margin-left: auto;
				margin-right: auto;
				width: 800px;
			}

			section h1 {
				font-size: 3em;
			}

			section h2 {
				font-size: 2em;
			}

			section h3 {
				font-size: 1.3em;
			}

			section p {
				font-size: 1.2em;
			}

			section.s1 {
				color: white;
				background-image: url(${unsafeCSS(s1Back)});
				background-size: cover;
				text-shadow: 0px 0px 2px black;
			}

			#dark {
				position: absolute;
				overflow: hidden;
				width: 100vw;
				height: 100vh;
				z-index: -1;
				background-image: url(${unsafeCSS(darkBack)});
				background-position: center;
			}

			#light {
				position: absolute;
				overflow: hidden;
				width: 100vw;
				height: 100vh;
				z-index: -1;
				background-image: url(${unsafeCSS(lightBack)});
				background-position: center;
				background-size: cover;
			}

			section.s2 {
				color: white;
				text-shadow: 0px 0px 2px black;
				padding-top: 100px;
				box-shadow: inset 0 8px 32px 32px rgba(0, 0, 0, 0.4);
			}

			section.s3 {
				color: black;
				background-color: white;
			}

			section.s4 {
				color: black;
				background-color: transparent;
				padding-bottom: 200px;
				text-shadow: 0px 0px 2px white;
			}

			section button {
				color: white;
				background-color: var(--primary-dark);
				border: 1px solid var(--primary-darker);
				border-radius: 2em;
				padding: 0.6em;
				padding-left: 2em;
				padding-right: 2em;
				margin-top: 0.2em;
				margin-bottom: 0.2em;
				margin-left: 2em;
				margin-right: 2em;
				font-size: 1.5em;
				font-weight: bold;
				box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4);
			}

			section button:hover {
				background-color: var(--primary-color);
				border: 1px solid var(--primary-dark);
			}

			section button.warn {
				color: white;
				background-color: var(--warn-dark);
				border: 1px solid var(--warn-darker);
			}

			section button.warn:hover {
				background-color: var(--warn-color);
				border: 1px solid var(--warn-dark);
			}

			section button:active {
				margin-top: 0.4em;
				margin-bottom: 0;
				margin-left: 2.2em;
				margin-right: 1.8em;
				box-shadow: none;
			}

			section button.info {
				color: white;
				background-color: var(--info-dark);
				border: 1px solid var(--info-darker);
			}

			.buttonbox {
				display: flex;
				justify-content: center;
			}

			section img {
				margin-left: 1em;
				margin-right: 1em;
			}

		`;
	}
	connectedCallback() {
		super.connectedCallback();
		let that = this;
		function adjustBackg() {
			var dark = <any>that.shadowRoot?.querySelector('#dark');
			var s1_5 = <any>that.shadowRoot?.querySelector('.s2');
			var s3 = <any>that.shadowRoot?.querySelector('.s3');
			let scy = s1_5.offsetTop - s1_5.offsetHeight*2/3 + (document.scrollingElement?.scrollTop || 0) * 0.5;
			dark.style.top = scy + 'px';
			var light = <any>that.shadowRoot?.querySelector('#light');
			var s4 = <any>that.shadowRoot?.querySelector('.s4');
			scy = s4.offsetTop - s4.offsetHeight*3/2 + ((document.scrollingElement?.scrollTop || 0) - s1_5.offsetTop) * 0.5;
			light.style.top = scy + 'px';
			if ((document.scrollingElement?.scrollTop || 0) < s3.offsetTop) {
				dark.style.display = 'block';
			} else {
				dark.style.display = 'none';
			}
			if ((document.scrollingElement?.scrollTop || 0) + window.innerHeight < s4.offsetTop) {
				light.style.display = 'none';
			} else {
				light.style.display = 'block';
			}
		}
		document.addEventListener('scroll',adjustBackg);
		window.addEventListener('load', adjustBackg);
	}

	render() {
		return html`
			<div id="dark">
			</div>
			<div id="light">
			</div>
			<section class="s1">
				<h1>Tired of the Traditional C/C++ Development Hassles?</h1>
				<p>Join the revolution in dependency management with GottaBe.io. Transform your development experience
					by simplifying complex project structures, reducing build errors, and focusing on what truly
					matters—creating impactful software. Wave goodbye to outdated processes and embrace a new era of
					coding efficiency.</p>
				<h2>Join the Future of C/C++ Development with GottaBe.io</h2>
				<p>Sign up for GottaBe.io today and join a growing community of forward-thinking developers. Start for
					free and gain access to all the tools you need to unleash the full potential of your projects. Let's
					build something extraordinary together. Your next big project deserves the best—give it
					GottaBe.io.</p>
				<div class="buttonbox">
					<div>
						<button class="warn">Sign up</button>
					</div>
					<div>
						<button class="info">Learn more</button>
					</div>
				</div>
			</section>
			<section class="s2">
				<h2>Navigating the Complexity of Modern C/C++ Builds</h2>
				<p>In today's fast-paced tech environment, managing C/C++ project environments is fraught with
					complexities and inefficiencies. GottaBe.io steps in to cut through the clutter, offering
					streamlined project builds, compatibility checks, and automated solutions that turn chaos into
					order. Visualize success with clear, manageable workflows designed for modern development
					challenges.</p>
				<h3>Get rid of complex tools that are not capable of fully managing your project.</h3>
				<p><img src="${complex01}"> <img src="${complex02}"></p>
			</section>
			<section class="s3">
				<h2>Experience the Cutting-Edge with GottaBe.io</h2>
				<p>Are you ready to boost your productivity and innovate like never before? GottaBe.io is not just a
					tool—it's your partner in redefining the development process. Our platform automates tedious tasks,
					simplifies dependency management, and ensures that your projects are secure, up-to-date, and
					scalable. Test drive GottaBe.io today and experience firsthand the advantages of modernized project
					management.</p>
				<video height="240" autoplay loop muted>
					<source src="${quickBuild}" type="video/mp4"/>
				</video>
			</section>
			<section class="s4">
				<h2>Empower Your Projects with Advanced GottaBe.io Features</h2>
				<p>GottaBe.io brings you a suite of powerful tools designed to enhance every aspect of your C/C++
					projects. From seamless multi-platform integration to comprehensive security checks and real-time
					collaboration features, every tool we offer is crafted to provide maximum efficiency and minimal
					frustration. Manage your dependencies effortlessly and focus on what you do best—innovative software
					development.</p>
			</section>

			<site-footer></site-footer>
			
			</div>
		`;
	}

	constructor() {
		super();
	}

}

declare global {
	interface HTMLElementTagNameMap {
		'home-not-logged': HomeNotLogged;
	}
}
