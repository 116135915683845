import {LitElement, html, customElement, css, property} from 'lit-element';
import '../components/toggle-button';
import '../components/my-dialog';
import {CookiesSettings} from "../types";
import {CookiesManagement} from "../services/cookies-management";

@customElement("cookies-manager")
class CookiesManager extends LitElement {

    static get styles() {
        return css`

            .container {
                display: flex;
                margin-top: 8px;
                margin-bottom: 8px;
                min-height: 36px;
            }

            .col {
                flex: 1;
                padding: 20px;
            }

            .left {
                flex: 1;
                color: #eee;
                margin-right: 10px;
            }

            .right {
                color: #eee;
            }

            @media (max-width: 768px) {
                .container {
                    flex-direction: column;
                }
            }
        `;
    }

    @property()
    cookiesSettings: CookiesSettings;

    constructor() {
        super();
        this.cookiesSettings = CookiesManagement.loadSettings();
    }

    protected render(): unknown {
        return html`
            <my-dialog width="600">
                <div slot="caption">Cookies preferences</div>
                <div>
                    <div class="container">
                        <div class="left"><strong>Essential</strong>: These cookies are necessary for the website to function and cannot be switched off.</div>
                        <div class="right"><toggle-button name="essential" disabled .checked="${this.cookiesSettings.essential}" @change="${this._handleChange}"></toggle-button></div>
                    </div>
                    <div class="container">
                        <div class="left"><strong>Performance and Analytics</strong>: Cookies that help us measure how visitors interact with website content.</div>
                        <div class="right"><toggle-button name="performanceAndAnalytics" .checked="${this.cookiesSettings.performanceAndAnalytics}" @change="${this._handleChange}"></toggle-button></div>
                    </div>
                    <div class="container">
                        <div class="left"><strong>Functionality</strong>: Cookies that enable video and social media features.</div>
                        <div class="right"><toggle-button name="functionality" .checked="${this.cookiesSettings.functionality}" @change="${this._handleChange}"></toggle-button></div>
                    </div>
                    <div class="container">
                        <div class="left"><strong>Advertising</strong>: Cookies used by advertising companies to serve ads that are relevant to your interests.</div>
                        <div class="right"><toggle-button name="advertising" .checked="${this.cookiesSettings.advertising}" @change="${this._handleChange}"></toggle-button></div>
                    </div>
                </div>
            </my-dialog>
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        let that = this;
        setTimeout(() => {
            this.cookiesSettings = CookiesManagement.loadSettings();
            if (!CookiesManagement.isAccepted()) {
                that.shadowRoot?.querySelector('my-dialog')?.show(false).then(() => {
                    CookiesManagement.saveSettings(true, that.cookiesSettings);
                });
            }
        }, 1);
    }

    editCookies() {
        let that = this;
        this.shadowRoot?.querySelector('my-dialog')?.show(false).then(() => {
            CookiesManagement.saveSettings(true, that.cookiesSettings);
        });
    }

    private _handleChange(e: CustomEvent) {
        let target = e.target || e.currentTarget;
        (<any>this.cookiesSettings)[(<any>target).getAttribute('name')] = e.detail.checked;
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'cookies-manager': CookiesManager;
    }
}
