import {CookiesSettings} from '../types';

interface SettingsCache {accepted: boolean; settings: CookiesSettings};

const cookiesKey = 'gottabe.data.cookies';
let settingsCache: SettingsCache | undefined;
const defaultValue: SettingsCache = {accepted: false, settings: {essential: true, performanceAndAnalytics: true, functionality: true, advertising: true}};

export class CookiesManagement {

    static loadSettings(): CookiesSettings {
        if (!settingsCache) {
            let valueStr = localStorage.getItem(cookiesKey);
            if (valueStr)
                settingsCache = <SettingsCache>JSON.parse(valueStr);
            else
                settingsCache = defaultValue;
        }
        return settingsCache.settings;
    }

    static isAccepted(): boolean {
        if (!settingsCache) {
            let valueStr = localStorage.getItem(cookiesKey);
            if (valueStr)
                settingsCache = <SettingsCache>JSON.parse(valueStr);
            else
                settingsCache = defaultValue;
        }
        return settingsCache.accepted;
    }

    static saveSettings(accepted: boolean, settings: CookiesSettings) {
        settingsCache = {accepted, settings};
        localStorage.setItem(cookiesKey, JSON.stringify(settingsCache));
    }

}