import { LitElement, html, customElement, property, css } from 'lit-element';
import {userService} from '../services/user-services';
import '../components/md-view';
import termsUseMdUrl from 'url:../../resources/terms-of-use.md';
import {defaultClient} from '../services/http-services';
import {navigator} from "../util/router";

const isEmpty = (el: Element): boolean => {
	if (el.tagName.toUpperCase() == 'INPUT') {
		let input = <HTMLInputElement> el;
		switch (input.type.toLowerCase()) {
			case 'password':
			case 'text':
			case 'datetime':
			case 'date':
				return (input.value || '').length == 0;
		}
	} else if (el.tagName.toUpperCase() == 'TEXTAREA') {
		return ((<HTMLTextAreaElement>el).value || '').length == 0;
	} else if (el.tagName.toUpperCase() == 'SELECT') {
		return (<HTMLSelectElement>el).selectedIndex == -1;
	}
	return false;
}

@customElement("register-form")
@navigator
class RegisterForm extends LitElement {

	static get styles() {
		return css`
			:host {
				display: block;
				width: 100%;
				height: 100vh;
				font-family: Arial, sans-serif;
			}

			.container {
				margin-left: auto;
				margin-right: auto;
				display: flex;
				max-width: 768px;
			}

			h2 {
				margin-bottom: 20px;
				color: var(--primary-dark);
			}

			.content {
				flex: 1;
				background-color: #fff;
				padding: 40px;
				border-left: 1px solid #ddd;
			}

			.content h1 {
				font-size: 24px;
				margin-bottom: 20px;
			}

			.content p {
				font-size: 14px;
				color: #666;
				margin-bottom: 20px;
			}

			.form-group {
				margin-bottom: 20px;
			}

			label {
				display: block;
				font-weight: bold;
				margin-top: 5px;
				color: #777;
			}

			input[type="text"], input[type="email"], input[type="date"], input[type="password"], textarea {
				width: 96%;
				padding: 10px;
				border: 1px solid #ddd;
				border-radius: 5px;
				margin-bottom: 10px;
			}

			input[type="checkbox"] {
				margin-right: 10px;
			}

			input[type="checkbox"] + label {
				display: inline-block;
			}

			button {
				color: white;
				background-color: var(--primary-dark);
				border: 1px solid var(--primary-darker);
				border-radius: 1em;
				padding: 0.3em 1em;
				margin: 0.1em 1em;
				font-size: 1em;
				font-weight: bold;
				box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4);
			}

			button:hover {
				background-color: var(--primary-color);
				border: 1px solid var(--primary-dark);
			}

			button:active {
				box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
			}

			.error {
				color: red;
			}

			.terms {
				max-height: 400px;
				overflow: auto;
				border: 1px solid var(--secondary-dark);
				padding-left: 4px;
				padding-right: 4px;
			}

			.confirmation {
				margin: 4px;
			}

			.confirmation p {
				font-size: 16px;
				color: #666;
			}

			.confirmation li {
				font-size: 14px;
				color: #666;
			}

			span.bold {
				font-weight: bold;
			}

			span.symbol {
				font-family: "Noto Color Emoji", sans-serif;
				font-weight: 400;
				font-size: 2em;
				font-style: normal;
				margin: -0.2em;
			}
		`;
	}

	@property()
	error?: string;

	@property()
	user: any;

	@property()
	errorFields: any;

	@property()
	step: number = 0; // Current step of the wizard

	termMdData?: string;

	render() {
		return html`
			<div class="container">
				<div class="content">
				<!-- Render different steps based on the current step -->
				${this.renderStep()}
				<!-- Navigation buttons -->
				<div style="margin-top: 20px;">
					${this.step > 0 && this.step < 5 ? html`<button @click="${this.prevStep}" type="button">Previous</button>` : ''}
					${this.step < 4 ? html`<button @click="${this.nextStep}" type="button">Next</button>` : ''}
					${this.step === 4 ? html`<button id="sendbutton" @click="${this.handleRegister}" type="button">Submit</button>` : ''}
				</div>
				</div>
			</div>
        `;
	}

	// Renders the current step of the wizard
	renderStep() {
		switch (this.step) {
			case 0:
				return this.renderTermsAcceptance();
			case 1:
				return this.renderAccountStep();
			case 2:
				return this.renderPersonalDataStep();
			case 3:
				return this.renderSocialStep();
			case 4:
				return this.renderPasswordStep();
			case 5:
				return this.renderConfirmationMessage();
			default:
				return html`<p>Invalid step.</p>`;
		}
	}

	// Step 0: Terms Acceptance
	renderTermsAcceptance() {
		return html`
			<form id="accountInfoForm">
				<h2>Terms of Use</h2>
				<div class="error">
					<h3>${this.error}</h3>
				</div>
				<div class="terms">
					<md-view mdData="${this.termMdData}"></md-view>
				</div>
				<div>
					<span class="error"><h3>${this.errorFields.termsAccepted}</h3></span>
					<input type="checkbox" name="termsAccepted" required @click="${this.handleAcceptTerms}" value="true"/>
					<label for="termsAccepted">I have read and agree to the <a href="/policies/terms" target="_blank">Terms of Use</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.</label>
				</div>
			</form>
		`;
	}

	// Step 1: Account Identification (Email, Username)
	renderAccountStep() {
		return html`
			<form id="accountInfoForm">
				<h2>Account Information</h2>
				<div class="error">
					<h3>${this.error}</h3>
				</div>
				<div>
					<label>E-Mail:</label>
					<span class="error"><h3>${this.errorFields.email}</h3></span>
					<input type="text" name="email" required @change="${this.handleChange}" .value="${this.user.email}"/>
				</div>
				<div>
					<label>Nickname:</label>
					<span class="error"><h3>${this.errorFields.nickname}</h3></span>
					<input type="text" name="nickname" required @change="${this.handleChange}" .value="${this.user.nickname}"/>
				</div>
			</form>
        `;
	}

	// Step 2: Personal Data (Name, Last Name, Birth Date)
	renderPersonalDataStep() {
		return html`
			<form id="personalDataForm">
				<h2>Personal Information</h2>
				<div class="error">
					<h3>${this.error}</h3>
				</div>
				<div>
					<label>Name:</label>
					<span class="error"><h3>${this.errorFields.name}</h3></span>
					<input type="text" name="name" required @change="${this.handleChange}" .value="${this.user.name}"/>
				</div>
				<div>
					<label>Last Name:</label>
					<span class="error"><h3>${this.errorFields.lastName}</h3></span>
					<input type="text" name="lastName" required @change="${this.handleChange}" .value="${this.user.lastName}"/>
				</div>
				<div>
					<label>Birth Date:</label>
					<span class="error"><h3>${this.errorFields.birthDate}</h3></span>
					<input type="date" name="birthDate" @change="${this.handleChange}" .value="${this.user.birthDate}"/>
				</div>
			</form>
        `;
	}

	// Step 3: Social Media (Github, Twitter)
	renderSocialStep() {
		return html`
			<form id="socialForm">
				<h2>Social Media</h2>
				<div class="error">
					<h3>${this.error}</h3>
				</div>
				<div>
					<label>Github Account:</label>
					<span class="error"><h3>${this.errorFields.githubAccount}</h3></span>
					<input type="text" name="githubAccount" @change="${this.handleChange}" .value="${this.user.githubAccount}"/>
				</div>
				<div>
					<label>Twitter Account:</label>
					<span class="error"><h3>${this.errorFields.twitterAccount}</h3></span>
					<input type="text" name="twitterAccount" @change="${this.handleChange}" .value="${this.user.twitterAccount}"/>
				</div>
			</form>
        `;
	}

	// Step 4: Password (Password, Confirm Password)
	renderPasswordStep() {
		return html`
			<form id="passwordForm">
				<h2>Security</h2>
				<div class="error">
					<h3>${this.error}</h3>
				</div>
				<div>
					<label>Password:</label>
					<span class="error"><h3>${this.errorFields.password}</h3></span>
					<input type="password" name="password" required @change="${this.handleChange}" .value="${this.user.password}"/>
				</div>
				<div>
					<label>Confirm Password:</label>
					<span class="error"><h3>${this.errorFields.confirmPassword}</h3></span>
					<input type="password" name="confirmPassword" required @change="${this.handleChange}" .value="${this.user.confirmPassword}"/>
				</div>
			</form>
        `;
	}

	renderConfirmationMessage() {
		return html`
			<form id="confirmForm">
				<h2>Welcome to GottaBe.io! <span class="symbol">🎉</span></h2>
				<div class="confirmation">
					<p>Congratulations on creating your account! You’re now part of a growing community of C++ developers. Here’s what you can do next:</p>
					<ul>
						<li><span class="bold">Publish and Manage Artifacts:</span> Easily upload and organize your C++ libraries and dependencies.</li>
						<li><span class="bold">Collaborate with Others:</span> Share your projects, collaborate with other developers, and receive feedback.</li>
						<li><span class="bold">Track Downloads and Reviews:</span> Monitor how your published artifacts are used and reviewed by the community.</li>
						<li><span class="bold">Customize Your Privacy:</span> Control who can view your information and projects.</li>
					</ul>
					<p>Start exploring the platform and make the most of these powerful features. We’re excited to see what you’ll create!</p>
				</div>
				<div>
					<button @click="${this.goToSignIn}" type="button">Sign in now!</button>
				</div>
			</form>
        `;
	}

	constructor() {
		super();
		this.user = this._newUser();
		this.errorFields = this._newUser();
	}

	connectedCallback() {
		super.connectedCallback();
		let idx = termsUseMdUrl.lastIndexOf('/');
		let baseUrl = termsUseMdUrl.substring(0, idx);
		let file = termsUseMdUrl.substring(idx);
		defaultClient.get(file, {baseUrl, type: 'text'}).then((async(data:any) => {
			this.termMdData = await data.text();
			this.performUpdate();
		}).bind(this));
	}

	private _cleanupErrors() {
		this.errorFields = this._newUser();
		this.error = '';
	}

	private checkValidity(): boolean {
		let q = this.shadowRoot?.querySelectorAll('input[required]') || [];
		let vals = Array.from(q).filter(isEmpty);
		vals.forEach(el => {
			let name = el.getAttribute('name');
			if (name) {
				this.errorFields[name] = 'You must provide a ' + name;
			}
		});
		this.performUpdate();
		return vals.length == 0;
	}

	private async asyncValidations(): Promise<boolean> {
		switch (this.step) {
			case 0:
				if ((<HTMLInputElement>this.shadowRoot?.querySelector('input[name=termsAccepted]')).checked)
					return true;
				this.error = "You must accept the terms to proceed!";
				return false;
			case 1:
				try {
					await userService.checkAvailability(this.user.email, this.user.nickname);
					return true;
				} catch(_e:any) {
					if (_e.response) {
						this.error = (await _e.response.json()).message;
					} else {
						this.error = _e.message;
					}
					return false;
				}
		}
		return true;
	}

	handleAcceptTerms(e:MouseEvent) {
		this.handleChange(e);
		let data = {x: e.x, y: e.y, timestamp: new Date().toISOString()};
		this.user.userAcceptanceData = JSON.stringify(data);
	}

	handleChange(e: any) {
		this._cleanupErrors();
		let target = e.target;
		if (target) {
			this.user[target.name] = target.value;
			this.errorFields = this._newUser(); // Reset error messages
		}
	}

	handleRegister(e: any) {
		e.preventDefault();
		if (this.checkValidity()) {
			userService.createNew(this.user)
				.then(((_v: any) => {
					this.step += 1;
					this.user = this._newUser();
					this.performUpdate();
				}).bind(this))
				.catch(((e: any) => {
					e.response.json().then(((v: any) => {
						this.error = v.message;
						if (v.fields)
							v.fields.forEach((f: any) => this.errorFields[f.id] = f.message);
						this.performUpdate();
					}).bind(this));
				}).bind(this));
		}
	}

	async nextStep() {
		if (this.checkValidity() && await this.asyncValidations()) {
			this._cleanupErrors();
			if (this.step < 4) this.step += 1;
		}
		this.performUpdate();
	}

	prevStep() {
		this._cleanupErrors();
		if (this.step > 0) this.step -= 1;
		this.performUpdate();
	}

	goToSignIn() {
		(<any>this).navigate("/login");
	}

	_newUser() {
		return { email: '', name: '', lastName: '', nickname: '', password: '', confirmPassword: '', birthDate: '', githubAccount: '', twitterAccount: '' };
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'register-form': RegisterForm;
	}
}
