import { LitElement, html, css, customElement } from 'lit-element';
import {style} from '../styles';
import '../components/md-view';
import '../components/site-footer';
import {defaultClient} from '../services/http-services';
import aboutMd from 'url:../../resources/about.md';

@customElement("about-page")
class AboutPage extends LitElement {

    static get styles() {
        return [style, css`
            :host {
              background-color: antiquewhite;
            }
            div {
              padding: 1em;
              margin-left: auto;
              margin-right: auto;
              max-width: 65em;
            }
        `];
    }

    aboutMdData?: string;

    render() {
        return html`
            <div>
                <md-view mdData="${this.aboutMdData}"></md-view>
            </div>
            <site-footer></site-footer>
        `;
    }
    connectedCallback() {
        super.connectedCallback();
        let idx = aboutMd.lastIndexOf('/');
        let baseUrl = aboutMd.substring(0, idx);
        let file = aboutMd.substring(idx);
        defaultClient.get(file, {baseUrl, type: 'text'}).then((async(data:any) => {
            this.aboutMdData = await data.text();
            this.performUpdate();
        }).bind(this));
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'about-page': AboutPage;
    }
}
