import {LitElement, html, css, customElement} from 'lit-element';

@customElement("site-footer")
class SiteFooter extends LitElement {

    static get styles() {
        return css`
            :host {
                color: black;
                background-color: var(--secondary-color);
                display: block;
            }
            footer {
                display: block;
                padding: 4em;
                padding-top: 6em;
                padding-bottom: 6em;
                text-align: center;
                max-width: 65em;
                margin-left: auto;
                margin-right: auto;
            }

          #footer {
            display: flex;
          }

          #footer div {
            flex: 1 1 auto;
            min-width: 0;
            min-height: 0;
          }

          ul {
            padding: 0;
          }

          li, #footer h3 {
            display: list-item;
            text-align: -webkit-match-parent;
            unicode-bidi: isolate;
            list-style-type: none;
            text-align: left;
            margin-bottom: 1em;
          }

          li > a {
            text-decoration: none;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
            color: var(--text-primary);
          }

          li > a:hover {
            color: var(--text-primary-darker);
          }

        `;
    }

    render() {
        return html`
            <footer class="cd2827bb bt b--black-10 mt4">
                <div id="footer" class="center mw9 pa3 flex flex-column flex-wrap-m flex-row-ns">
                    <div class="flex-auto pa4-ns pa3 w-100 w-10-l">
                        <div class="_802b5507 pt3">
                            <a target="_blank" rel="noopener noreferrer nofollow"
                               href="https://github.com/gottabe-io/">
                                <svg viewBox="0 0 60 60" aria-hidden="true" width="50"
                                     height="50">
                                    <g transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)"
                                       fill="#185580" stroke="none">
                                        <path d="M0 300 l0 -300 300 0 300 0 0 300 0 300 -300 0 -300 0 0 -300z m500
\t\t\t\t\t\t\t\t\t\t150 l0 -50 -150 0 -150 0 0 -100 0 -100 50 0 50 0 0 50 0 50 100 0 100 0 0
\t\t\t\t\t\t\t\t\t\t-100 0 -100 -50 0 -50 0 0 50 0 50 -50 0 -50 0 0 -50 0 -50 -100 0 -100 0 0
\t\t\t\t\t\t\t\t\t\t200 0 200 200 0 200 0 0 -50z"/>
                                    </g>
                                </svg>
                            </a>
                        </div>
                        <!--div class="_802b5507 pt3">
                        \t\t\t\t\t\t\t<a target="_blank" rel="noopener noreferrer nofollow" href="https://github.com"
                        \t\t\t\t\t\t\t\taria-label="GitHub">
                        \t\t\t\t\t\t\t\t<svg aria-hidden="true" role="img" class="" viewBox="0 0 16 16" width="50"
                        \t\t\t\t\t\t\t\t\theight="50" fill="black" style="display:inline-block;user-select:none;vertical-align:top">
                        \t\t\t\t\t\t\t\t\t<path fill-rule="evenodd"
                        \t\t\t\t\t\t\t\t\t\td="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z">
                        \t\t\t\t\t\t\t\t\t</path>
                        \t\t\t\t\t\t\t\t</svg>
                        \t\t\t\t\t\t\t</a>
                        \t\t\t\t\t\t</div-->
                    </div>
                    <div class="flex-auto pa4-ns pa3 w-30-ns w-50-m">
                        <h3 id="support" class="_1b8c0a9f">Help&amp;Support</h3>
                        <ul class="list pl0" aria-labelledby="support">
                            <li class="pv1"><a class="link" href="https://docs.gottabe.io">Help</a></li>
                            <li class="pv1"><a class="link" href="https://github.com/advisories">Advisories</a></li>
                            <!--li class="pv1"><a class="link" href="http://status.npmjs.org/">Status</a></li-->
                            <li class="pv1"><a class="link" href="/support">Contact</a></li>
                        </ul>
                    </div>
                    <div class="flex-auto pa4-ns pa3 w-30-ns w-50-m">
                        <h3 id="company" class="_1b8c0a9f">Company</h3>
                        <ul class="list pl0" aria-labelledby="company">
                            <li class="pv1"><a class="link" href="/about">About</a></li>
                            <li class="pv1"><a class="link" href="/news">News</a></li>
                            <li class="pv1"><a class="link" href="https://github.blog/tag/gottabe-io/">Blog</a></li>
                            <li class="pv1"><a class="link" href="https://twitter.com/gottabe-io/">Twitter</a></li>
                        </ul>
                    </div>
                    <div class="flex-auto pa4-ns pa3 w-30-ns w-50-m">
                        <h3 id="terms" class="_1b8c0a9f">Terms &amp; Policies</h3>
                        <ul class="list pl0" aria-labelledby="terms">
                            <li class="pv1"><a class="link" href="/policies/">Policies</a></li>
                            <li class="pv1"><a class="link" href="/policies/terms">Terms of Use</a></li>
                            <li class="pv1"><a class="link" href="/policies/conduct">Code of Conduct</a></li>
                            <li class="pv1"><a class="link" href="/privacy">Privacy</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        `;
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'site-footer': SiteFooter;
    }
}
