import {LitElement, html, customElement, css, property} from 'lit-element';
import {UserPrivacyVO, UserVO} from "../types";
import {userService} from '../services/user-services';
//import {style} from '../styles';
import {Timer} from '../util/mutex';

@customElement("profile-page")
class ProfilePage extends LitElement {

	static get styles() {
		return [css`
			:host {
				display: block;
				width: 100%;
				height: 100vh;
				font-family: Arial, sans-serif;
			}
			.container {
				margin-left: auto;
				margin-right: auto;
				display: flex;
				max-width: 1024px;
				height: 100%;
			}
			.sidebar {
				width: 250px;
				background-color: var(--primary-color);
				color: white;
				padding: 20px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			.sidebar h2 {
				margin-bottom: 20px;
			}
			.sidebar button {
				background: transparent;
				color: white;
				border: none;
				margin: 10px 0;
				padding: 10px;
				width: 100%;
				text-align: left;
				font-size: 16px;
				cursor: pointer;
				border-radius: 5px;
				transition: background-color 0.3s ease;
			}
			.sidebar button:hover, .sidebar button.active {
				background-color: var(--primary-dark);
			}
			.content {
				flex: 1;
				background-color: #fff;
				padding: 40px;
				border-left: 1px solid #ddd;
			}
			.content h1 {
				font-size: 24px;
				margin-bottom: 20px;
			}
			.content p {
				font-size: 14px;
				color: #666;
				margin-bottom: 20px;
			}
			.form-group {
				margin-bottom: 20px;
			}
			label {
				display: block;
				font-weight: bold;
				margin-bottom: 5px;
				color: #777;
			}
			input[type="text"], input[type="email"], textarea {
				width: 96%;
				padding: 10px;
				border: 1px solid #ddd;
				border-radius: 5px;
				margin-bottom: 10px;
			}
			input[type="checkbox"] {
				margin-right: 10px;
			}
		`];
	}

	@property({ type: Object })
	private profile: UserVO;
	@property({ type: Object })
	private privacy: UserPrivacyVO;
	@property()
	private activeTab: string = 'Account';
	@property()
	error ?: string;

	private timer: Timer;

	constructor() {
		super();
		this.profile = <any>{};
		this.privacy = <any>{};
		this.timer = new Timer();
	}

	connectedCallback() {
		super.connectedCallback();
		this._updateData().catch(((e:any) => {
			this.error = e.message;
		}).bind(this));
	}

	private async _updateData() {
		this.error = '';
		this.profile = await userService.currentUserProfile();
		this.privacy = await userService.currentUserPrivacy();
	}

	render() {
		return html`
			<div class="container">
				<!-- Sidebar with Tabs -->
				<div class="sidebar">
					<h2>Settings</h2>
					<button class="${this.activeTab === 'Account' ? 'active' : ''}" @click="${() => this.changeTab('Account')}">Account</button>
					<p>Manage your personal account information.</p>
					<button class="${this.activeTab === 'Social' ? 'active' : ''}" @click="${() => this.changeTab('Social')}">Social</button>
					<p>Connect your social accounts.</p>
					<button class="${this.activeTab === 'Privacy' ? 'active' : ''}" @click="${() => this.changeTab('Privacy')}">Privacy</button>
					<p>Control your privacy settings.</p>
				</div>

				<!-- Content Area -->
				<div class="content">
					${this.renderContent()}
				</div>
			</div>
		`;
	}

	changeTab(tab: string) {
		this.activeTab = tab;
	}

	renderContent() {
		switch (this.activeTab) {
			case 'Account':
				return this.renderAccountTab();
			case 'Social':
				return this.renderSocialTab();
			case 'Privacy':
				return this.renderPrivacyTab();
			default:
				return html`<p>Select a tab to view the corresponding settings.</p>`;
		}
	}

	renderAccountTab() {
		return html`
			<h1>Account Settings</h1>
			<p>Manage your personal account information below.</p>
			<form>
				<div class="form-group">
					<label>Name:</label>
					<input type="text" .value="${this.profile.name}" @change="${this.handleChangeData}" id="name"/>
				</div>
				<div class="form-group">
					<label>Last Name:</label>
					<input type="text" .value="${this.profile.lastName}" @change="${this.handleChangeData}" id="lastName"/>
				</div>
				<div class="form-group">
					<label>Email:</label>
					<input type="email" .value="${this.profile.email}" @change="${this.handleChangeData}" id="email"/>
				</div>
				<div class="form-group">
					<label>Nickname:</label>
					<input type="text" .value="${this.profile.nickname}" @change="${this.handleChangeData}" id="nickname"/>
				</div>
				<div class="form-group">
					<label>Description:</label>
					<textarea .value="${this.profile.description}" @change="${this.handleChangeData}" id="description"></textarea>
				</div>
			</form>
		`;
	}

	renderSocialTab() {
		return html`
			<h1>Social Settings</h1>
			<p>Connect your social media accounts below.</p>
			<form>
				<div class="form-group">
					<label>Github Account:</label>
					<input type="text" .value="${this.profile.githubAccount}" @change="${this.handleChangeData}" id="githubAccount"/>
				</div>
				<div class="form-group">
					<label>Twitter Account:</label>
					<input type="text" .value="${this.profile.twitterAccount}" @change="${this.handleChangeData}" id="twitterAccount"/>
				</div>
			</form>
		`;
	}

	renderPrivacyTab() {
		return html`
			<h1>Privacy Settings</h1>
			<p>Control the visibility of your personal information.</p>
			<form>
				<div>
					<input style="float:left" type="checkbox" .checked="${this.privacy.showEmail}" @change="${this.handleChangePrivacy}" id="showEmail"/>
					<label>Show E-Mail</label>
				</div>
				<div>
					<input style="float:left" type="checkbox" .checked="${this.privacy.showTwitter}" @change="${this.handleChangePrivacy}" id="showTwitter"/>
					<label>Show Twitter Account</label>
				</div>
				<div>
					<input style="float:left" type="checkbox" .checked="${this.privacy.showGithub}" @change="${this.handleChangePrivacy}" id="showGithub"/>
					<label>Show Github Account</label>
				</div>
				<div>
					<input style="float:left" type="checkbox" .checked="${this.privacy.showName}" @change="${this.handleChangePrivacy}" id="showName"/>
					<label>Show Name</label>
				</div>
			</form>
		`;
	}

	handleChangeData(event: any) {
		const profile: any = {...this.profile};
		profile[event.target.id] = event.target.value;
		this.profile = profile;
		this.triggerUpdateData();
	}

	handleChangePrivacy(event: any) {
		const privacy: any = {...this.privacy};
		privacy[event.target.id] = event.target.checked;
		this.privacy = privacy;
		this.triggerUpdatePrivacy();
	}

	triggerUpdateData() {
		this.timer.cancel();
		this.timer.start(3000).then(() => userService.updateCurrentUserProfile(this.profile));
	}

	triggerUpdatePrivacy() {
		this.timer.cancel();
		this.timer.start(3000).then(() => userService.updateCurrentUserPrivacy(this.privacy));
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'profile-page': ProfilePage;
	}
}
