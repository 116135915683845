import { LitElement, html, customElement, property, css } from 'lit-element';
import { navigator } from 'lit-element-router';
import {login} from '../services/http-services';
//import {style} from '../styles';

@customElement("login-form")
@navigator
class LoginForm extends LitElement {

	static get styles() {
		return [css`
			.outer-container {
				display: flex;
				margin-left: auto;
				margin-right: auto;
				max-width: 768px;
				margin-top: 40px;
			}

			.left-section, .right-section {
				width: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
			}

			.left-section {
				background-color: var(--primary-dark);
				color: white;
				flex-direction: column;
			}

			.left-section h2 {
				margin-bottom: 20px;
			}

			.login-form {
				width: 300px;
				display: flex;
				flex-direction: column;
			}

			.login-form input {
				margin: 10px 0;
				padding: 15px;
				border: none;
				border-radius: 5px;
				font-size: 16px;
			}

			.login-form button {
				margin: 20px 0;
				padding: 15px;
				border: none;
				border-radius: 5px;
				background-color: var(--primary-dark);
				color: white;
				font-size: 16px;
				cursor: pointer;
			}

			.login-form button:hover {
				background-color: var(--primary-darker);
			}

			.right-section {
				background-color: #f4f4f4;
				flex-direction: column;
				text-align: center;
			}

			.right-section h2 {
				margin-bottom: 20px;
				color: var(--primary-dark);
			}

			.right-section p {
				margin-bottom: 20px;
				font-size: 18px;
				color: #666;
			}

			.signup-btn {
				background-color: var(--primary-dark);
				color: white;
				padding: 15px 30px;
				border: none;
				border-radius: 5px;
				text-decoration: none;
				font-size: 18px;
				cursor: pointer;
			}

			.signup-btn:hover {
				background-color: var(--primary-darker);
			}
		`];
	}

	@property()
	error ?: string;

	@property()
	newUser : boolean;

	render() {
		return html`
			<div class="outer-container">
				<!-- Left Section for Login -->
				<div class="left-section">
					<h2>Welcome Back!</h2>
					<form class="login-form" @submit="${this.handleLogin}">
						<input type="hidden" name="grant_type" value="password" />
						<div class="error">
							<h3>${this.error}</h3>
						</div>
						<input type="email" name="username" placeholder="Email Address" required>
						<input type="password" name="password" placeholder="Password" required>
						<button type="submit">Login</button>
					</form>
				</div>

				<!-- Right Section for Signup -->
				<div class="right-section">
					<h2>New to GottaBe.io?</h2>
					<p>Join us and unlock a world of opportunities to manage and share your C++ dependencies with ease.</p>
					<p>Be part of a growing community of developers. Sign up now to publish and collaborate on innovative projects!</p>
					<a href="/register" class="signup-btn" @click="${this.handleNewUser}">Create an Account</a>
				</div>
			</div>
  		`;
	}

	constructor() {
		super();
		this.newUser = false;
	}

	async handleLogin(e: any) {
		e.preventDefault();
		let formEl = this.shadowRoot?.querySelector('.login-form');
		if (!formEl) return;
		let formData = new FormData(<HTMLFormElement>formEl);
		try {
			await login(formData);
			(<any>this).navigate("/");
		} catch(e:any) {
			if (e.response) {
				let v : any = await e.response.json();
				this.error = v.message;
				console.log(v);
				this.performUpdate();
			} else
				console.error(e);
		}
	}

	handleNewUser(e: any) {
		this.error = undefined;
		e.preventDefault();
		(<any>this).navigate("/register");
	}

}

declare global {
	interface HTMLElementTagNameMap {
		'login-form': LoginForm;
	}
}
