import {css, customElement, html, LitElement, property} from 'lit-element';
import {PackageDataVO, PackageGroupVO, OwnerVO} from "../types";
import {style} from '../styles';
import {packageService} from "../services/package-services";
import '../components/my-dialog';
import {setValue} from "../util/utils";
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';

@customElement("owner-page")
class OwnerPage extends LitElement {

	static get styles() {
		return [style,css`
			:host {
				display: block;
				align-items: center;
				width: 100%;
                color: aliceblue;
			}
            table {
                width: 100%;
                border: none;
            }
            th {
                background: #585c64;
                border: none;
            }
            td {
                background: #383c44;
                border: none;
            } 
            td.actions {
                text-align: center;
            }
            a {
                color: white;
                text-decoration: none;
                font-weight: bold;
            }
            a:hover {
                color: yellow;
            }
            .buttons {
                display: flex;
            }
            .form-field + .form-field {
              margin-top: 8px;
            }
		`];
	}

	@property()
	packages: Array<PackageDataVO>;

	@property({attribute: true})
	nickname?: string;

	private error?: string;

	private group: PackageGroupVO;

	private errorFields: any;

	private __package: PackageDataVO;

	private owner?: OwnerVO;

	render() {
		return html`
			<div>
                <div class="error">
                    <h3>${this.error}</h3>
                </div>
                ${this.owner ? html`
                <table>
                    <tr><th>Package</th><th>Versions</th><th>Date</th><th>Actions</th></tr>
                    ${this.packages.map((this._package).bind(this))}
                </table>
                ` : html`
                    
                `}
            </div>
            <my-dialog id="groupDlg" showCancel="true">
                <div slot="caption">Dialog</div>
                <div>
                    <div class="form-field">
                        <label>Name:</label>
                        <span class="error">
                            <h3>${this.errorFields.name}</h3>
                        </span>
                        <input type="text" name="group.name" .value="${this.group.name}" @change="${this._handleChange}"/>
                    </div>
                    <div class="form-field">
                        <label>Description:</label>
                        <span class="error">
                            <h3>${this.errorFields.description}</h3>
                        </span>
                        <input type="text" name="group.description" .value="${this.group.description}" @change="${this._handleChange}"/>
                    </div>
                </div>
            </my-dialog>
            <my-dialog id="packageDlg" showCancel="true">
                <div slot="caption">Dialog</div>
                <div>
                    <div class="form-field">
                        <label>Group:</label>
                        <span class="error">
                            <h3>${this.errorFields['group.name']}</h3>
                        </span>
                        <input type="text" name="__package.group.name" .value="${this.__package?.group?.name}" @change="${this._handleChange}"/>
                    </div>
                    <div class="form-field">
                        <label>Name:</label>
                        <span class="error">
                            <h3>${this.errorFields.name}</h3>
                        </span>
                        <input type="text" name="__package.name" .value="${this.__package.name}" @change="${this._handleChange}"/>
                    </div>
                </div>
            </my-dialog>
		`;
	}

	constructor() {
		super();
		this.packages = [];
		this.errorFields = {};
		this.group = {name:'', description:''};
		this.__package = {name:'', group:{name:''}, type: 'PACKAGE'};
	}

	connectedCallback() {
		super.connectedCallback();
		this._updateData().catch(((e:any) => {this.error = e.message; console.error(e)}).bind(this));
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	private _package(pack: PackageDataVO, _i: number) {
		if (!pack) return ``;
		return unsafeHTML(`
            <tr>
                <td rowspan="${Math.max(1,pack.releases?.length || 0)}">${pack.group?.name}/${pack.name}</td>
                ${pack.releases?.map((release,j) => `
                    <td>${release.version}</td>
                    <td>${release.releaseDate ? new Date(Date.parse(release.releaseDate)).toLocaleString() : ''}</td>
                    <td class="actions"><a href="/packages/${pack.group?.name}/${pack.name}">Open</a></td>
                    ${j < (pack.releases?.length || 0) - 1 ? `</tr><tr>`: ''}`
		).join('')}
                ${!(pack.releases?.length) ? `<td colspan="3" style="text-align: center">No release done yet!</td>` : '' }
            </tr>
        `);
	}

	private async _updateData() {
		if (this.nickname) {
			this.packages = await packageService.packagesFrom(this.nickname);
			if (this.packages.length > 0) {
				this.owner = this.packages[0].group?.owner;
			}
		}
		await this.performUpdate();
	}

	private _handleChange(e:any) {
		let target = e.target;
		if (target) {
			setValue(this,target.name,target.value);
			this.errorFields = {};
		}
	}

}

declare global {
	interface HTMLElementTagNameMap {
		'owner-page': OwnerPage;
	}
}
